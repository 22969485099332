/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import videos from "../videos/TS_0621_Final-transcode.mp4";

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=62a2e6ccc03c347bac421db9").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]


class InfoView extends React.Component {

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '62ad87803249a9914ca31192'
    htmlEl.dataset['wfSite'] = '62a2e6ccc03c347bac421db9'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = InfoView.Controller !== InfoView ? transformProxies(this.props.children) : {

    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{
          __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/hst2-1.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="af-class-body">
            <div className="af-class-container">
              <div className="af-class-header">
                <div className="af-class-header-link">
                  <a href="/" className="af-class-button-3 w-button"><t className="hover-underline-animation">BACK TO HOME PAGE </t></a>
                </div>
              </div>
              <div className="af-class-banner">
                <div className="af-class-banner-1">
                  <div className="af-class-banner-1-t-1">Initial Art Offering</div>
                  <div className="af-class-banner-1-t-2">
                    <div className="af-class-text-block-4">Times Square Heist is the largest web3 interactive art movement created by BVAT for its IAO. &nbsp;Participants will take part in the Times Square heist movement and purchase $BRAIN to gain access to the entire Brain In a Vat ecosystem.</div>
                    <a href="https://docs.bvat.io/rac+bvat/hacking-activity-iao" target="_blank" className="af-class-button-2 af-class-iao-button w-button">
                      <t className="hover-underline-animation">LEARN MORE </t>
                    </a>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `<video autoplay loop muted playsinline width="100%"
                            height="100%">
                            <source src=${videos} type="video/mp4" />
                            Your browser does not support the video tag.
                      </video>`,
                    }}
                  />
                </div>
                <div className="af-class-banner-1-5">
                  <div className="af-class-banner-1-5-hafl">
                    <div className="af-class-text-block-3">Times square Heist steps:</div>
                  </div>
                  <div className="af-class-banner-1-5-hafl">
                    <div className="af-class-banner-1-5-info">
                      <div className="af-class-rule-number">01</div>
                      <div className="af-class-rule-text">Confirm your web3 anonymous identity (connect your wallet)</div>
                    </div>
                    <div className="af-class-line" />
                    <div className="af-class-banner-1-5-info">
                      <div className="af-class-rule-number">02</div>
                      <div className="af-class-rule-text">Upload anything you want to say on &nbsp;Times Square billboards</div>
                    </div>
                    <div className="af-class-line" />
                    <div className="af-class-banner-1-5-info">
                      <div className="af-class-rule-number">03</div>
                      <div className="af-class-rule-text">Purchase at least 0.1ETH $BRAIN</div>
                    </div>
                    <div className="af-class-line" />
                    <div className="af-class-banner-1-5-info">
                      <div className="af-class-rule-number">04</div>
                      <div className="af-class-rule-text">Brain in a vat Anonymous team will help you encrypt and upload your text to the 60+ Times Square digital billboards they hacked.</div>
                    </div>
                    <div className="af-class-line" />
                    <div className="af-class-banner-1-5-info">
                      <div className="af-class-rule-number">05</div>
                      <div className="af-class-rule-text">Your words will be presented in an artistic form at certain times. Details of the artistic expression and relevant information can be found on Official Twitter</div>
                    </div>
                  </div>
                </div>
                <div data-w-id="4304b04e-e941-1618-f5dd-ab13ea28a612" style={{ opacity: 0 }} className="af-class-banner-2">
                  <div className="af-class-banner-2-t-1">Initial art offering rule</div>
                  <div className="af-class-banner-2-t-2">Through contributing ETH to particpate in Hacking Activity and gain 10% of total SBarin tokens and other NFT Awards</div>
                  <div className="af-class-card-holder">
                    <div className="af-class-card">
                      <div className="af-class-card-header">First 30M</div>
                      <div className="af-class-card-text">1 ETH=10K</div>
                    </div>
                    <div className="af-class-card">
                      <div className="af-class-card-header">30M-60M</div>
                      <div className="af-class-card-text">1.5 ETH=10K</div>
                    </div>
                    <div className="af-class-card">
                      <div className="af-class-card-header">60M-100M</div>
                      <div className="af-class-card-text">1.75 ETH=10K</div>
                    </div>
                  </div>
                  <div className="af-class-banner-2-t-3">100.000.000 $Brain will be allocated to the initial Art Offering</div>
                </div>
                <div className="af-class-banner-3">
                  <div data-w-id="d38a9325-8d7b-f3b2-3a8c-af0b6f53ad77" className="af-class-banner-3-t-1">We are not bluffing, We are hacking | We are not bluffing, We are hacking</div>
                  <div data-w-id="2b8fc684-ee44-e44f-9d94-7985057c47f4" className="af-class-banner-3-t-1 af-class-banner-3-t-2">We are not bluffing, We are hacking | We are not bluffing, We are hacking</div>
                </div>
                <div className="af-class-banner-4">
                  <div className="af-class-banner-4-card af-class-left"><img src="images/BVAT_Coin_4.gif" loading="lazy" alt />
                    <div className="af-class-banner-4-t">
                      <div className="af-class-banner-4-t-1">$Brain Tokenomic</div>
                      <div className="af-class-banner-4-t-2">Know more about tokenomic of $Brain and BVAT ecosystem</div>
                    </div>
                    <a href="https://docs.bvat.io/usdbrain-tokenomics/about-usdbrain" target="_blank" className="af-class-button-4 w-button">
                      <t className="hover-underline-animation">KNOW MORE</t>
                    </a>
                  </div>
                  <div className="af-class-banner-4-card af-class-right"><img src="images/New.gif" loading="lazy" width={430} alt />
                    <div className="af-class-banner-4-t">
                      <div className="af-class-banner-4-t-1">NFT Express</div>
                      <div className="af-class-banner-4-t-2">Charge (Stake) your Brain to get BVAT EXPRESS NFT</div>
                    </div>
                    <a href="https://docs.bvat.io/bvat-nft/bvat-express" target="_blank" className="af-class-button-4 w-button">
                      <t className="hover-underline-animation">KNOW MORE</t>
                    </a>
                  </div>
                </div>
              </div>
              <div className="af-class-foorter">
                <div className="af-class-footer-contain">
                  <div className="af-class-quick-link">Quick Link</div>
                  <div className="af-class-logo-div">
                    <a href="https://twitter.com/HTSACTIVITY" target="_blank" className="af-class-logo-element w-inline-block"><img src="images/twitter.svg" loading="lazy" alt className="af-class-logo-img" /></a>
                    <a href="#" className="af-class-logo-element w-inline-block"><img src="images/discord.svg" loading="lazy" alt className="af-class-logo-img" /></a>
                    <a href="https://docs.bvat.io/" target="_blank" className="af-class-logo-element w-inline-block"><img src="images/gitbook-svgrepo-com.png" loading="lazy" alt className="af-class-logo-img" /></a>
                    <a href="https://medium.com/@BVATgroup" target="_blank" className="af-class-logo-element w-inline-block"><img src="images/Medium.png" loading="lazy" alt className="af-class-logo-img" /></a>
                    <a href="https://www.bvat.io/" target="_blank" className="af-class-logo-element w-inline-block"><img src="images/Frame-27.svg" loading="lazy" alt className="af-class-logo-img" /></a>
                  </div>
                </div>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default InfoView

/* eslint-enable */