import React, { useEffect, useState } from 'react'

const scripts = [
    { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=62a2e6ccc03c347bac421db9").then(body => body.text()), isAsync: false },
    { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]


export const CountDown = ({ props }) => {
    const calculateTimeLeft = () => {
        const difference = +new Date(1655289016000) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
    });

    return (
        <div className="af-class-countdown-div">
            <div className="af-class-conutdown-sub-div">
                <h1 className="af-class-hackingtime">Hacking Times Square</h1>
                <h1 className="af-class-heading-2 af-class-title">WARNING!<br />HACKING <br />BEGINS IN</h1>
                <div className="af-class-time">
                    <div className="af-class-time-div">
                        <div className="af-class-div-block">
                            <h1 id="days" className="af-class-heading-2">{timeLeft.days}</h1>
                            <h1 className="af-class-heading-4">Days</h1>
                        </div>
                        <h1 className="af-class-heading-5">:</h1>
                        <div className="af-class-div-block">
                            <h1 id="hours" className="af-class-heading-2">{timeLeft.hours}</h1>
                            <h1 className="af-class-heading-4">Hours</h1>
                        </div>
                    </div>
                    <h1 className="af-class-heading-5 af-class-divide-co">:</h1>
                    <div className="af-class-time-div">
                        <div className="af-class-div-block">
                            <h1 id="minutes" className="af-class-heading-2">{timeLeft.minutes}</h1>
                            <h1 className="af-class-heading-4">MInutes</h1>
                        </div>
                        <h1 className="af-class-heading-5">:</h1>
                        <div className="af-class-div-block">
                            <h1 id="seconds" className="af-class-heading-2">{timeLeft.seconds}</h1>
                            <h1 className="af-class-heading-4">Seconds</h1>
                        </div>
                    </div>
                </div>
                <div className="af-class-details">
                    <button
                        data-w-id="ebebf745-f750-4f3a-5c3b-1ca7f86e2b66"
                        className="af-class-button-2 w-button"
                        onClick={() => {
                            props.setState({
                                isShown: true,
                                modal: "ModalOpen",
                            });
                        }}
                    >
                        <t className="hover-underline-animation">Hack </t>
                    </button>
                    <a href="/info" className="af-class-button-2 w-button"><t className="hover-underline-animation">Details </t></a>
                </div>
            </div>
        </div >
    )
}