import React, { useState } from "react";
import { BsPlayFill, BsFillPauseFill, BsFillStopFill } from "react-icons/bs";
// import { MdSkipNext } from "react-icons/md";
import audioSrc from "./audio/ncr.m4a";
import soundImg from "./audio/sound.gif";

import "./index.css";

export const MediaPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [songIdx, setSongIdx] = useState(0);
  const songList = [
    {
      author: "",
      title: "",
      src: audioSrc,
    },
  ];
  return (
    <div>
      <div class="media-player">
        <div class="control">
          <div player="play-pause" class="play-wrapper">
            {isPlaying ? (
              <BsFillPauseFill
                class="control-icon"
                size={30}
                loading="lazy"
                alt=""
                onClick={() => {
                  const audioElement = document.getElementById("audio");
                  audioElement.pause();
                  setIsPlaying(false);
                }}
              />
            ) : (
              <BsPlayFill
                class="control-icon"
                size={30}
                loading="lazy"
                alt=""
                onClick={() => {
                  const audioElement = document.getElementById("audio");
                  audioElement
                    .play()
                    .then(() => {
                      console.log("music is playing");
                    })
                    .catch((e) => {
                      console.log(e.message);
                    });
                  setIsPlaying(true);
                }}
              />
            )}
          </div>
        </div>
        {/* <div class="textWrapper">
          <div player="song-title" class="title">
            {songList[songIdx].title}
          </div>
          <div player="song-title" class="subtitle">
            {songList[songIdx].author}
          </div>
        </div> */}
        <img
          class="control-icon"
          src={soundImg}
          style={{
            width: "30px",
            height: "30px",
            "margin": "5px",
            display: isPlaying ? "inline-block" : "none",
          }}
          alt=""
        />
      </div>

      <audio id="audio" src={songList[songIdx].src} loop />
    </div>
  );
};
