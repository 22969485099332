/* eslint-disable */

import React from 'react';
import { createScope, map, transformProxies } from './helpers';
import { CountDown } from './countdown';
import { MediaPlayer } from '../mediaplayer/index';
import videos from "../videos/BVAT_Heist_BW_0614-transcode.mp4";

import './IndexView.css';
import './Button.css';

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=62a2e6ccc03c347bac421db9").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
  { loading: fetch("https://momentjs.com/downloads/moment.min.js").then(body => body.text()), isAsync: false },
  { loading: fetch("https://momentjs.com/downloads/moment-timezone-with-data.min.js").then(body => body.text()), isAsync: false },
  { loading: Promise.resolve("var timer,now=new Date,now=new Date,then=new Date(2022,7,1,15,30,0,0),utcOffset=moment.tz(moment.utc(),\"Europe/London\").utcOffset(),localOffset=moment().utcOffset(),offset=utcOffset-localOffset,compareDate=new Date(then)-now.getDate()-60*offset*1e3;function timeBetweenDates(e){var t,o,e=new Date(e),n=new Date,e=e.getTime()-n.getTime();e<=0?($(\"#days\").text(\"0\"),$(\"#hours\").text(\"0\"),$(\"#minutes\").text(\"0\"),$(\"#seconds\").text(\"0\")):(n=Math.floor(e/1e3),e=Math.floor(n/60),t=Math.floor(e/60),o=Math.floor(t/24),t%=24,e%=60,n%=60,$(\"#days\").text(o),$(\"#hours\").text(t),$(\"#minutes\").text(e),$(\"#seconds\").text(n))}timer=setInterval(function(){timeBetweenDates(compareDate)},1e3);"), isAsync: false },
]


class IndexView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isShown: false,
      modal: "ModalClosed"
    };
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '62a2e6ccc03c348df6421dbe'
    htmlEl.dataset['wfSite'] = '62a2e6ccc03c347bac421db9'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = IndexView.Controller !== IndexView ? transformProxies(this.props.children) : {

    }
    return (
      <span>
        <style dangerouslySetInnerHTML={{
          __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/hst2-1.webflow.css);
        ` }} />
        <span className="af-view">
          <div>
            <div className="af-class-main">
              <div data-poster-url="videos/BVAT_Heist_BW_0614-poster-00001.jpg" data-video-urls="videos/BVAT_Heist_BW_0614-transcode.mp4" data-autoplay="true" data-loop="true" data-wf-ignore="true" className="af-class-background-video w-background-video w-background-video-atom">
                <video id="9e6bfa0e-cd69-36c1-f28d-482bf6ea0836-video" autoPlay loop style={{ backgroundImage: 'url("videos/BVAT_Heist_BW_0614-poster-00001.jpg")' }} muted playsInline data-wf-ignore="true" data-object-fit="cover">
                  <source src={videos} data-wf-ignore="true" />
                </video>
              </div>
              <div data-w-id="87ae9be3-18e8-4e77-cc24-278d8cb0f164" className="af-class-spill-over">
                <CountDown props={this} />
                <div className="af-class-introducation-div">
                  <div className="af-class-div-block-9"><img src="images/BVAT-Logo.svg" loading="lazy" alt className="af-class-image-2" />
                    <div className="af-class-div-block-10">
                      <h1 className="af-class-heading-3">Brain in a Vat</h1>
                      <p className="af-class-paragraph-2">Brain In a Vat (BVAT)) group is dedicated to disrupting the current web3 and NFT Market. All of our ideas were inspired by the philosophical thought experiment “Brain in a Vat.”</p>
                    </div>
                  </div>
                </div>
                <div className="af-class-social-media-div">
                  <div className="af-class-div-block-8">
                    <div className="af-class-div-block-6">
                      <h1 className="af-class-heading-4 af-class-follow">Follow Us</h1>
                      <a href="#" className="af-class-link-block w-inline-block">
                        <div className="af-class-div-block-5"><img src="images/discord.svg" loading="lazy" width={24} height={24} alt className="af-class-image" /></div>
                      </a>
                      <a href="https://twitter.com/HTSACTIVITY" target="_blank" className="af-class-link-block w-inline-block">
                        <div className="af-class-div-block-5"><img src="images/twitter.svg" loading="lazy" width={24} height={24} alt className="af-class-image" /></div>
                      </a>
                    </div>
                    <div className="af-class-div-block-6">
                      <h1 className="af-class-heading-4 af-class-follow">Quick Link</h1>
                      <a href="https://docs.bvat.io/" target="_blank" className="af-class-link-block w-inline-block">
                        <div className="af-class-div-block-5"><img src="images/gitbook-svgrepo-com.png" loading="lazy" width={24} height={24} alt className="af-class-image" /></div>
                      </a>
                      <a href="https://www.bvat.io/" target="_blank" className="af-class-link-block w-inline-block">
                        <div className="af-class-div-block-5"><img src="images/Frame-27.svg" loading="lazy" width={24} height={24} alt className="af-class-image" /></div>
                      </a>
                      <a href="https://medium.com/@BVATgroup" target="_blank" className="af-class-link-block w-inline-block">
                        <div className="af-class-div-block-5"><img src="images/Medium.png" loading="lazy" width={24} height={24} alt className="af-class-image" /></div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.isShown && <div className={`af-class-modal-bg Modal ${this.state.modal}`} >
                <div className="af-class-modal">
                  <div className="af-class-modal-text af-class-modal-text-header">What are we?</div>
                  <div className="af-class-modal-text">60+ TS Digital Billboard, and the Creators of a new World! Our mission is to fully equipped with Wisdom And Freedom to; Voice our opinions, Take hold of anything we favor, an advertisement, a company logo, membership in a DAO organization, a NFT, an interesting story, a spirit, a declaration of war, a maxim, Express our concerns of racial equality, a vulnerable group, or other international issues, Navigate a certain NFT project, or Create the next 100x, 1000x investment opportunity and Achieve something remarkable. ...(about IAO)</div>
                  <button data-w-id="3a682ac3-616f-c770-4328-9243832d2e97" className="af-class-button-2 w-button"
                    onClick={() => {
                      this.setState({
                        isShown: false,
                        modal: "ModalClosed"
                      })
                    }}
                  >
                    <t className="hover-underline-animation">Back</t>
                  </button>
                </div>
              </div>}
            </div>
            <div className="af-class-cursor">
              <div data-w-id="ccf69e4c-7685-10e7-3451-3e947cc6f257" className="af-class-cursor-ring" />
            </div>
            <div className="af-class-html-embed w-embed">
              <style dangerouslySetInnerHTML={{ __html: "\n.af-view .af-class-cursor {pointer-events:none;}\n.af-view .af-class-nav-link::before {content:\"●\"; margin-right:20px;}\n.af-view .af-class-project-title {-webkit-text-stroke: 1px #ffffff;}\n" }} />
            </div>
            <MediaPlayer />
            {/* [if lte IE 9]><![endif] */}
            {/*  MOMENT.JS  */}
            {/*  MOMENT.JS TIMEZONE DATA  */}
          </div>
        </span>
      </span>
    )
  }
}

export default IndexView

/* eslint-enable */